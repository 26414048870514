<script setup lang="ts">
import { ref, computed } from "vue";
import { useScroll } from "@vueuse/core";
const el = ref<HTMLElement | null>(null);
const smooth = ref(false);
const behavior = computed(() => (smooth.value ? "smooth" : "auto"));
const { x } = useScroll(el, { behavior });
const props = defineProps({
    categories: {
        type: Object,
        required: false,
    },
});
</script>

<template>
    <div ref="el" class="catalog-mini-items catalog-sec-items">
        <div
            v-for="childCategory in props.categories"
            :key="childCategory.id"
            class="catalog-item-wrap"
        >
            <a :href="'/' + childCategory.full_url" class="catalog-sec-item">
                <span>{{ childCategory.name }}</span>
                <img
                    :src="'/' + childCategory.image"
                    :alt="childCategory.image_alt"
                    :title="childCategory.image_title"
                />
            </a>
        </div>

        <div class="arrows" v-if="props.categories.length > 3">
            <div class="scroll-arrow left-arrow" @click="x -= 100">
                <img src="/design/images/icon/arrow-left.svg" alt="" />
            </div>
            <div class="scroll-arrow right-arrow" @click="x += 100">
                <img src="/design/images/icon/arrow-right.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.arrows {
    display: flex;
    width: 1115px;
    height: 130px;
    justify-content: space-between;
    position: absolute;
    top: 410px;
}

.scroll-arrow {
    position: relative;
    transform: translateY(-50%);
    background-color: #fff;
    -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 100px;
    width: 31px;
    height: 29px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-arrow {
    content: "";
    right: 0;
}
</style>
