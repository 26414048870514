/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";
/*import '../css/app.css';*/

import Alpine from "alpinejs";
import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
window.Alpine = Alpine;

Alpine.start();

//const app = createApp(App);
/*app.component(
    "favorite-badge",
    defineAsyncComponent(() => import("./components/FavoriteBadge.vue")),
);*/
/*app.component(
    "app",
    defineAsyncComponent(() => import("App.vue")),
);*/
//app.component("Pagination", Bootstrap5Pagination);
//app.mount("#app");
createApp(App).mount("#app");
