<script setup>
import { onMounted, computed, reactive, ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { Bootstrap5Pagination } from "laravel-vue-pagination";
/*import Filters from "./view/Filters.vue";*/
import Catalog from "./view/Catalog.vue";
import Preloader from "./components/Preloader.vue";
import { watchDebounced } from "@vueuse/core";
let currentCategory = ref(window.currentCategory);
let isSubcategory = ref(window.isSubcategory);

/*onUpdated(() => {
    getFilterList().then((data) => {
        filterList = data;
    });
    console.log("updated");
});*/
//let filterList = reactive([]);
//let brands = ref([]);

/*const getData = async () => {
    return fetch(
        //`http://127.0.0.1:8000/api/v1/complexes-map-filters?${params}`,
        "/api/v1/products?" + new URLSearchParams({ category: category.value }),
    ).then((res) => res.json());
};*/

/*const getFilterList = async () => {
    const res = await fetch(
        "/api/v1/products/filters?" +
            new URLSearchParams({ category: currentCategory }),
    ).then((res) => res.json());

    return res;
};*/

/*async function getFilterList() { //этот
    return await fetch(
        "/api/v1/products/filters?" +
            new URLSearchParams({ category: currentCategory }),
    ).then((res) => res.json());
}*/

let filters = ref([]);
//const brands = ref([]);

const getFilterList = async () => {
    return fetch(
        "/api/v1/products/filters?" +
            new URLSearchParams({
                category: currentCategory.value,
                isSubcategory: isSubcategory.value || false,
            }),
    ).then(async (res) => await res.json());
};

const category = ref(currentCategory);

/*watch(
    { category, subCategory },
    async () => {
        const res = await fetch(
            "/api/v1/products/filters?" +
                new URLSearchParams({
                    category: currentCategory.value,
                    subCategory: subCategory.value || null,
                }),
        );
        filters.value = await res.json();
    },
    {
        // must pass deep option to watch for changes on object properties
        deep: true,
        // can also pass immediate to handle that first request AND when queries change
        immediate: true,
    },
);*/
/*const getFilterList = async () => {
    const res = await fetch(
        "/api/v1/products/filters?" +
            new URLSearchParams({ category: currentCategory.value }),
    ).then((res) => res.json());
    filters = res.data;
};*/

//const test = ref('')

onMounted(() => {
    //getFilterList();
    getFilterList().then((data) => {
        filters.value = data.data;
    });

    /*getData().then((data) => {
        products.value = data;
    });*/
    /*getFilterList().then((data) => {
        //return (filterList = data);
        ({ product_filters, category, brands, price } = data);
    });*/
    console.log("mounted");
});

//let { product_filters, ...test } = filters || {};

/*getFilterList().then((FilterList) => {
    FilterList;
});*/

/*const getFilterList = async () => {
    return fetch("/api/v1/products/filters").then((res) => res.json());
};*/

let minPrice = ref(0);
let maxPrice = ref(500000);
/*let maxPrice = computed(() => {
    if (products.value.max_price) {
        //console.log(filters.value.filters_array);
        return products.value.max_price;
    } else {
        return 500000;
    }
});*/
//let maxPrice = ref(500000);
//let maxPrice = filters.value.price;

let brand = ref(""); //12
let page = ref(1);
let perPage = ref(12);
let sort = ref("");

const sortNew = () => {
    sort.value = "new";
};

const sortAvailability = () => {
    sort.value = "sortAvailability";
};

const sortClear = () => {
    sort.value = "";
};

/*const category = computed(() => {
    return currentCategory;
    //return author.books.length > 0 ? 'Да' : 'Нет'
});*/

/*const category = computed({
    get() {
        return isActiveCategory.value;
    },
    set(newValue) {
        isActiveCategory.value = newValue;
    },
});*/

const minP = ref(0);
const maxP = ref(500000);

const onInputMin = useDebounceFn(() => {
    minP.value = minPrice.value;
}, 500);

/*function clickedFn() {
    clicked.value += 1;
    onInput();
}*/

const onInputMax = useDebounceFn(() => {
    maxP.value = maxPrice.value;
}, 500);

/*const onInputMin = useDebounceFn(() => {
    return minPrice.value;
}, 500);*/

const filter = ref([]);

/*let selectedFilters = ref([
    { name: "Разрешение", value: "5 Мп" },
    { name: "Исполнение", value: "Уличное" },
]);*/

/*let selectedFilters = ref([
    { name: "Разрешение", value: "5 Мп" },
    { name: "Исполнение", value: "Уличное" },
]);*/

let selectedFilters = ref([]);
let isActiveFilter = ref([]);
function selectItem(id, name, value) {
    selectedFilters.value = [...selectedFilters.value, { id, name, value }];
    //console.log()
}

function checkFavorite(id) {
    if (props.favorites) {
        return props.favorites.includes(id);
    }
}

function removeItem(id) {
    selectedFilters.value = selectedFilters.value.filter((x) => x.id !== id);
}

/*const selectedFilters = (name, value) => {
    console.log(name, value);
    //return { name: name, value: value };
    return { name: "Разрешение", value: "5 Мп" };
    // emit('onAddTask', {title: title.value, description: description.value})
};*/
/*const selectItem = ({ name, value }) => {
    console.log(selectedFilters.value);
    return (selectedFilters.value = [
        ...selectedFilters.value,
        {
            name,
            value,
        },
    ]);
};*/

let selectedFilter = computed(() => {
    if (selectedFilters.value) {
        return JSON.stringify(selectedFilters.value);
    }
});

const queries = ref({
    sort: sort,
    //"filter[category]": "6148",
    //"price[min]": minPrice,
    "price[min]": minP,
    "price[max]": maxP,
    category: category,
    brand: brand,
    page: page,
    perPage: perPage,
    filter: selectedFilter,
    //"filter[name]": filterName,
    //"filter[name]": selectedFilters.name,
    //"filter[value]": filterValue,
    //"filter[value]": selectedFilters.value,
});

let products = ref([]);
let price = ref([]);
const loading = ref(false);
/*const getProducts = () => {
    loading.value = true;
    return (
        fetch(
            `/api/v1/products?${new URLSearchParams(queries.value).toString()}`,
        )
            //.then(async (res) => await res.json());
            .then((response) => {
                products.value = response.json();
                loading.value = false;
            })
    );
};*/

/*watch(
    queries,
    async () => {
        loading.value = true;
        const res = await fetch(
            `/api/v1/products?${new URLSearchParams(queries.value).toString()}`,
        );
        products.value = await res.json();
        loading.value = false;
    },
    {
        // must pass deep option to watch for changes on object properties
        deep: true,
        // can also pass immediate to handle that first request AND when queries change
        immediate: true,
    },
);*/

watch(
    queries,
    async () => {
        loading.value = true;
        const res = await fetch(
            `/api/v1/products?${new URLSearchParams(queries.value).toString()}`,
        );
        products.value = await res.json();
        loading.value = false;
    },
    {
        // must pass deep option to watch for changes on object properties
        deep: true,
        // can also pass immediate to handle that first request AND when queries change
        immediate: true,
    },
);

//const selectedClass = ref(false);
let isActiveBrand = ref();
let isActiveCategory = ref();

/*function selectItem(name, value) {
    filterName.value = name;
    filterValue.value = value;
    //console.log(id);
}*/

function selectBrandItem(id) {
    isActiveBrand.value = id;
    brand.value = id;
    //console.log(id);
}

function selectCategoryItem(id) {
    isActiveCategory.value = id;
    category.value = id;
    //console.log(id);
}

function clearFilters() {
    brand.value = "";
    isActiveBrand.value = "";
    category.value = currentCategory.value;
    isActiveCategory.value = "";
    minPrice.value = 0;
    maxPrice = 500000;
    queries.perPage = 12;
    queries.filter = "";
    selectedFilters.value.length = 0;
    window.location.reload();
    // filters_arr.value.length = 0;
    //filters_array.value.length = 0;
    /*console.log(filters_arr);
    selectedFilters.value.forEach((item, index) => {
        // есть доступ к внешней области видимости и `parentMessage`
        // но переменные `item` и `index` доступны только здесь
        console.log(item, index);
    });*/
    //filters.value.filters_array["PIR-датчик"].isActive = false;
    //filters.value = "";
}
/*watch(
    queries,
    useDebounceFn(() => {
        getProducts();
    }, 300),
);

onMounted(() => {
    getProducts();
});*/
//console.log(JSON.parse(currentScene[0].next_steps));
//console.log(filters);
/*let test = {
    name: "Вася",
    age: 35,
};*/
/*
for (let key in test) {
    //console.log(key);
    console.log(test[key]);
    //console.log(test.name);
    //console.log(test.age);
}
*/

/*for (let filter in filters) {
    //console.log(key);
    console.log(filter);
    //console.log(test.name);
    //console.log(test.age);
}*/
/*const processedProducts = computed(() => {
    return filters.value.map(...)
})*/

let categories = computed(() => {
    if (filters.value.category) {
        //console.log(filters.value.category.get_child_category);
        return filters.value.category.get_child_category;
    }
    //return filters.value.category.get_child_category;
    //return author.books.length > 0 ? 'Да' : 'Нет'
});

let brands = computed(() => {
    if (filters.value.brands) {
        //console.log(filters.value.brands);
        return filters.value.brands;
    }
});

/*let filters_arr = computed(() => {
    if (filters.value.filters_array) {
        //console.log(filters.value.filters_array);
        return filters.value.filters_array;
    }
});*/
let filters_arr = computed({
    // getter
    get() {
        if (filters.value.filters_array) {
            return filters.value.filters_array;
        }
    },
    set(newValue) {},
    // setter
    /*set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        //[firstName.value, lastName.value] = newValue.split(" ");
        filters.value.filters_array["PIR-датчик"].isActive = false;
    },*/
});
</script>

<template>
    <div class="row">
        <div class="col-12 col-xl-3">
            <div class="catalog-sidebar">
                <div class="sidebar__filter-links">
                    <form method="GET" id="filter_chapters" action="">
                        <div
                            class="accordion-collapse show"
                            aria-labelledby="heading4"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body" style="padding: 0">
                                <div class="filter-check-inp">
                                    <div
                                        class="personal__basket-check"
                                        style="
                                            flex-direction: column;
                                            align-items: flex-start;
                                            margin-left: 10px;
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="catalog-filter">
                    <div class="catalog-filter-bg catalog-filter-show"></div>
                    <div class="catalog-filter-title">
                        <div class="catalog-filter-title-wrap">
                            <span class="filter-title">Фильтры</span>
                            <button
                                class="btn-close catalog-filter-show"
                            ></button>
                        </div>
                        <!--                        <a
                            href="{{ url()->current() }}"
                            class="main-btn catalog-filter-btn"
                            >Сбросить параметры</a
                        >-->
                        <button
                            @click="clearFilters"
                            class="main-btn catalog-filter-btn"
                        >
                            Сбросить параметры
                        </button>
                    </div>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <span class="accordion-header" id="heading1">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse1"
                                    aria-expanded="true"
                                    aria-controls="collapse1"
                                >
                                    Цена
                                </button>
                            </span>
                            <div
                                id="collapse1"
                                class="accordion-collapse show"
                                aria-labelledby="heading1"
                                data-bs-parent="#accordionExample"
                            >
                                <div class="MultiRangeSliderContainer"></div>
                                <div class="accordion-body">
                                    <form id="filter_price">
                                        <div class="sidebar-filter-range">
                                            <div class="range range1">
                                                <div
                                                    class="range-price range-price1"
                                                >
                                                    <input
                                                        type="number"
                                                        id="minPrice1"
                                                        class="minPrice"
                                                        v-model="minPrice"
                                                        placeholder="0"
                                                    />
                                                    <span>-</span>
                                                    <input
                                                        type="number"
                                                        id="maxPrice1"
                                                        class="maxPrice"
                                                        v-model="maxPrice"
                                                        placeholder="500000"
                                                    />
                                                </div>
                                                <div class="range-slider"></div>
                                                <div
                                                    class="range-input range-input1"
                                                >
                                                    <input
                                                        type="range"
                                                        class="min"
                                                        min="0"
                                                        max="500000"
                                                        v-model="minPrice"
                                                        step="100"
                                                        @input="onInputMin"
                                                    />
                                                    <input
                                                        type="range"
                                                        class="max"
                                                        min="2400"
                                                        max="500000"
                                                        v-model="maxPrice"
                                                        step="100"
                                                        @input="onInputMax"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isSubcategory" class="accordion-item">
                            <span class="accordion-header" id="heading3">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse3"
                                    aria-expanded="false"
                                    aria-controls="collapse3"
                                >
                                    Подкатегории
                                </button>
                            </span>
                            <div
                                id="collapse3"
                                class="accordion-collapse collapse"
                                aria-labelledby="heading3"
                                data-bs-parent="#accordionExample"
                            >
                                <div class="accordion-body">
                                    <div class="filter-screen-dioganal">
                                        <button
                                            v-for="subCategory in categories"
                                            :key="subCategory.id"
                                            type="button"
                                            :id="'vendor_' + subCategory.id"
                                            :class="{
                                                selected:
                                                    isActiveCategory ===
                                                    subCategory.id,
                                            }"
                                            @click="
                                                selectCategoryItem(
                                                    subCategory.id,
                                                )
                                            "
                                            class="screen-dioganal-btn"
                                            :data-vendor="subCategory.id"
                                        >
                                            {{ subCategory.name }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <span class="accordion-header" id="heading4">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse4"
                                    aria-expanded="false"
                                    aria-controls="collapse4"
                                >
                                    Производители
                                </button>
                            </span>
                            <div
                                id="collapse4"
                                class="accordion-collapse collapse"
                                aria-labelledby="heading4"
                                data-bs-parent="#accordionExample"
                            >
                                <div class="accordion-body">
                                    <div class="filter-screen-dioganal">
                                        <button
                                            v-for="brand in brands"
                                            :key="brand.id"
                                            type="button"
                                            :id="'vendor_' + brand.id"
                                            :class="{
                                                selected:
                                                    isActiveBrand === brand.id,
                                            }"
                                            @click="selectBrandItem(brand.id)"
                                            class="screen-dioganal-btn"
                                            :data-vendor="brand.id"
                                        >
                                            {{ brand.name }}
                                            <span>
                                                {{ brand.products_count }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        -->
                        <div
                            v-if="isSubcategory"
                            class="accordion-item"
                            v-for="filters in filters_arr"
                        >
                            <span
                                class="accordion-header"
                                :id="'heading' + filters[0].id"
                            >
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="
                                        '#collapse' + filters[0].id
                                    "
                                    aria-expanded="false"
                                    :aria-controls="'collapse' + filters[0].id"
                                >
                                    {{ filters[0].name }}
                                </button>
                            </span>
                            <div
                                :id="'collapse' + filters[0].id"
                                class="accordion-collapse collapse"
                                :class="{
                                    show: queries.filter.includes(
                                        filters[0].id,
                                    ),
                                }"
                                :aria-labelledby="'heading' + filters[0].id"
                                data-bs-parent="false"
                            >
                                <!--                                data-bs-parent="#accordionExample"-->
                                <div class="accordion-body">
                                    <div class="filter-screen-dioganal">
                                        <template
                                            v-for="filter in filters"
                                            :key="filter.id"
                                        >
                                            <button
                                                v-if="!filter.isActive"
                                                :class="{
                                                    selected: filter.isActive,
                                                }"
                                                type="button"
                                                :id="filter.id"
                                                @click="
                                                    selectItem(
                                                        filter.id,
                                                        filter.name,
                                                        filter.value,
                                                        (filter.isActive =
                                                            !filter.isActive),
                                                    )
                                                "
                                                class="screen-dioganal-btn"
                                            >
                                                {{ filter.value
                                                }}<!--<span>1</span>-->
                                            </button>
                                            <button
                                                v-if="filter.isActive"
                                                :class="{
                                                    selected: filter.isActive,
                                                }"
                                                type="button"
                                                :id="filter.id"
                                                @click="
                                                    removeItem(
                                                        filter.id,
                                                        (filter.isActive =
                                                            !filter.isActive),
                                                    )
                                                "
                                                class="screen-dioganal-btn"
                                            >
                                                {{ filter.value
                                                }}<!--<span>1</span>-->
                                            </button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input
                            type="hidden"
                            name="filter"
                            id="filterInput"
                            value="<?php echo isset($_GET['filter']) ? htmlspecialchars(trim($_GET['filter'])) : ''; ?>"
                        />
                        <form>
                            <div class="accordion" id="accordionExample"></div>
                            <!--                            <button
                                type="button"
                                id="btnfilter"
                                class="main-btn catalog-filter-btn"
                            >
                                Применить параметры
                            </button>-->
                        </form>
                    </div>
                </div>
            </div>
            <!--            <filters end/>-->
        </div>

        <div class="col-12 col-xl-9">
            <Preloader :loading="loading" />
            <div v-if="!loading">
                <catalog
                    @sortNew="sortNew"
                    @sortAvailability="sortAvailability"
                    @sortClear="sortClear"
                    :products="products"
                    :categories="categories"
                    :loading="loading"
                />
                <button
                    class="main-btn catalog-cards-btn"
                    style="margin-bottom: 20px"
                    id="load-more-btn"
                    @click="perPage += 12"
                >
                    ПОКАЗАТЬ ЕЩЕ
                </button>
                <div class="paginate-products">
                    <Bootstrap5Pagination
                        :data="products"
                        :limit="15"
                        align="center"
                        @pagination-change-page="queries.page = $event"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#load-more-btn {
    display: flex;
    align-items: center;
    margin: auto;
}

.mark {
    color: black;
    border-radius: 0.25rem !important;
    box-shadow: 0 0 0 1px rgba(121, 82, 179, 0.7);
    padding: 5px;
}
/*input[type="number"] {
    -moz-appearance: textfield;
}*/
</style>
