<script setup>
import ProductCard from "../components/ProductCard.vue";
/*import ProductCardHorizontal from "../components/ProductCardHorizontal.vue";*/
import SubCategory from "../components/SubCategory.vue";
//import { Bootstrap5Pagination } from "laravel-vue-pagination";
import Preloader from "../components/Preloader.vue";

let favoritesProducts = window.productsInFavorite.split(",");

let favorites = favoritesProducts.map(function (item, index, arr) {
    let number = parseInt(item);
    return isNaN(number) ? item : number;
});

const props = defineProps({
    products: {
        //type: Object,
        required: true,
    },
    categories: {
        //type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
        required: true,
    },
});

const emit = defineEmits(["sortNew", "sortAvailability", "sortClear"]);
</script>
<template>
    <div class="catalog-product">
        <div v-if="props.categories" class="catalog-mini-wrap">
            <sub-category :categories="props.categories" />
        </div>
        <!--        <div class="catalog__product-info-text">
            {!! $category->description !!}
            <a href="#!" class="catalog-product-link">читать полностью</a>
        </div>-->

        <!--        <div class="catalog__product-info-text_for-mob">
            @if (Str::length($category?->description) > 550)
            <div class="short_text">
                {!! substr($category->description, 0, 545) !!} ...
            </div>
            <div class="full_text" style="display: none">
                {!! $category->description !!}
            </div>
            <a href="#!" class="catalog-product-link show_full_text"
                >читать полностью</a
            >
            @else
            <div class="short_text">{!! $category->description !!}</div>
            @endif
        </div>-->

        <div class="catalog__cards-content">
            <div class="catalog-cards-top">
                <div class="filter__view">
                    <a href="#!" class="grid-view active">
                        <img
                            src="/design/images/icon/plitka.png"
                            alt=""
                            style="width: 25px"
                        />
                    </a>
                    <a href="#!" class="list-view">
                        <img
                            src="/design/images/icon/spisok.png"
                            alt=""
                            style="width: 25px"
                        />
                    </a>
                </div>
                <a
                    @click="$emit('sortClear')"
                    href="#"
                    class="catalog-cards-delete none"
                >
                    <button
                        class="catalog-analiz-btn catalog-filter-show catalog-analiz-img-mob"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29"
                            height="28"
                            viewBox="0 0 29 28"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_4_7918)">
                                <path
                                    d="M20.0263 9.53741C20.0263 9.30535 19.9341 9.08279 19.77 8.9187C19.6059 8.7546 19.3833 8.66241 19.1513 8.66241H7.02375C6.81165 7.87823 6.32192 7.19779 5.64567 6.74766C4.96942 6.29753 4.15269 6.10837 3.34741 6.21535C2.54212 6.32234 1.80311 6.71819 1.26784 7.32927C0.732578 7.94035 0.4375 8.72505 0.4375 9.53741C0.4375 10.3498 0.732578 11.1345 1.26784 11.7456C1.80311 12.3566 2.54212 12.7525 3.34741 12.8595C4.15269 12.9665 4.96942 12.7773 5.64567 12.3272C6.32192 11.877 6.81165 11.1966 7.02375 10.4124L19.1513 10.4124C19.3833 10.4124 19.6059 10.3202 19.77 10.1561C19.9341 9.99204 20.0263 9.76948 20.0263 9.53741ZM3.8125 11.1387C3.49544 11.1404 3.185 11.048 2.92053 10.8731C2.65605 10.6982 2.44946 10.4487 2.32692 10.1563C2.20438 9.86384 2.17142 9.54161 2.23221 9.23042C2.29301 8.91924 2.44482 8.6331 2.6684 8.40829C2.89199 8.18347 3.17729 8.0301 3.48813 7.9676C3.79898 7.90511 4.12139 7.9363 4.41449 8.05723C4.70759 8.17817 4.95819 8.38339 5.13453 8.6469C5.31086 8.91042 5.405 9.22035 5.405 9.53741C5.40501 9.96058 5.23751 10.3665 4.93911 10.6666C4.6407 10.9666 4.23566 11.1364 3.8125 11.1387Z"
                                    fill="black"
                                />
                                <path
                                    d="M13.4634 19.32H19.1509C19.383 19.32 19.6056 19.2278 19.7697 19.0637C19.9338 18.8996 20.0259 18.677 20.0259 18.445C20.0259 18.2129 19.9338 17.9903 19.7697 17.8262C19.6056 17.6621 19.383 17.57 19.1509 17.57H13.4634C13.2734 16.8612 12.8548 16.235 12.2727 15.7883C11.6905 15.3417 10.9772 15.0996 10.2434 15.0996C9.50967 15.0996 8.7964 15.3417 8.21422 15.7883C7.63204 16.235 7.21348 16.8612 7.02344 17.57H1.33594C1.10387 17.57 0.881313 17.6621 0.717219 17.8262C0.553124 17.9903 0.460938 18.2129 0.460938 18.445C0.460938 18.677 0.553124 18.8996 0.717219 19.0637C0.881313 19.2278 1.10387 19.32 1.33594 19.32H7.02344C7.21348 20.0287 7.63204 20.6549 8.21422 21.1016C8.7964 21.5482 9.50967 21.7903 10.2434 21.7903C10.9772 21.7903 11.6905 21.5482 12.2727 21.1016C12.8548 20.6549 13.2734 20.0287 13.4634 19.32ZM8.64219 18.445C8.64046 18.1279 8.73289 17.8174 8.90778 17.553C9.08267 17.2885 9.33214 17.0819 9.62458 16.9594C9.91701 16.8368 10.2392 16.8039 10.5504 16.8647C10.8616 16.9255 11.1477 17.0773 11.3726 17.3008C11.5974 17.5244 11.7508 17.8097 11.8132 18.1206C11.8757 18.4314 11.8446 18.7538 11.7236 19.0469C11.6027 19.34 11.3975 19.5906 11.1339 19.767C10.8704 19.9433 10.5605 20.0374 10.2434 20.0375C9.82027 20.0375 9.41431 19.87 9.11426 19.5716C8.81422 19.2732 8.6445 18.8681 8.64219 18.445Z"
                                    fill="black"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4_7918">
                                    <rect
                                        width="28"
                                        height="28"
                                        fill="white"
                                        transform="translate(0.683594)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    Сбросить
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                    >
                        <path
                            d="M8.23318 8.58983C8.18625 8.58985 8.13978 8.5806 8.09643 8.5626C8.05309 8.54461 8.01373 8.51823 7.98061 8.48498L1.51489 2.02012C1.44783 1.95306 1.41016 1.8621 1.41016 1.76726C1.41016 1.67242 1.44783 1.58147 1.51489 1.5144C1.58196 1.44734 1.67291 1.40967 1.76775 1.40967C1.86259 1.40967 1.95355 1.44734 2.02061 1.5144L8.48632 7.98012C8.53614 8.03012 8.57004 8.09375 8.58375 8.16299C8.59746 8.23223 8.59036 8.30399 8.56335 8.3692C8.53633 8.43441 8.49062 8.49016 8.43196 8.52943C8.3733 8.56869 8.30434 8.58971 8.23375 8.58983H8.23318Z"
                            fill="black"
                        />
                        <path
                            d="M1.76753 8.58983C1.6969 8.58982 1.62785 8.56889 1.56911 8.52966C1.51037 8.49044 1.46456 8.43469 1.43748 8.36946C1.41039 8.30423 1.40324 8.23243 1.41693 8.16313C1.43062 8.09384 1.46454 8.03015 1.51439 7.98012L7.98011 1.5144C8.01331 1.4812 8.05273 1.45486 8.09612 1.43689C8.1395 1.41892 8.186 1.40967 8.23296 1.40967C8.27992 1.40967 8.32642 1.41892 8.36981 1.43689C8.41319 1.45486 8.45261 1.4812 8.48582 1.5144C8.51903 1.54761 8.54537 1.58703 8.56334 1.63042C8.58131 1.6738 8.59056 1.7203 8.59056 1.76726C8.59056 1.81422 8.58131 1.86072 8.56334 1.90411C8.54537 1.94749 8.51903 1.98691 8.48582 2.02012L2.02011 8.48583C1.98692 8.51893 1.94752 8.54515 1.90418 8.563C1.86084 8.58084 1.81441 8.58996 1.76753 8.58983Z"
                            fill="black"
                        />
                    </svg>
                </a>
                <div class="catalog-cards-drop catalog-cards-drop2">
                    <span class="catalog-cards-drop-spn">Сортировка:</span>
                    <div class="dropdown">
                        <a
                            class="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            По...
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                            >
                                <path
                                    d="M0 3.75L7.5 11.25L15 3.75H0Z"
                                    fill="#333333"
                                />
                            </svg>
                        </a>

                        <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                        >
                            <li>
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="$emit('sortNew')"
                                    >По новинкам</a
                                >
                            </li>
                            <li>
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="$emit('sortAvailability')"
                                    >По наличию</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="catalog__cards catalog__cards-2">
                <!--                <Preloader :loading="loading" />-->
                <div class="row" v-if="props.products.data">
                    <template
                        v-for="product in props.products.data"
                        :key="product.id"
                    >
                        <div class="col-6 col-lg-4 mb-3 grid-item">
                            <product-card
                                :product="product"
                                :favorites="favorites"
                            />
                        </div>
                        <div
                            class="col-12 mb-3 list-item"
                            style="display: none"
                        >
                            <!--                        <product-card-horizontal />-->
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
