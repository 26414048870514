<script setup>
import { computed, ref } from "vue";
const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
    favorites: {
        type: Array,
        required: false,
    },
});

function checkFavorite(id) {
    if (props.favorites) {
        return props.favorites.includes(id);
    }
}
const check = computed(checkFavorite);
</script>

<template>
    <div
        class="product-card"
        :data-product-id="props.product.id"
        :data-product-sku="props.product.sku"
    >
        <div class="product-like">
            <!-- <a href="#!" class="analiz-icon icon-red">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <g clip-path="url(#clip0_303_2210)">
                      <path d="M11.1855 6.56651V24.2996C11.1855 24.5573 11.3945 24.7662 11.6522 24.7662H15.3855C15.6432 24.7662 15.8521 24.5573 15.8521 24.2996V6.56651C15.8521 6.3088 15.6432 6.09985 15.3855 6.09985H11.6522C11.3945 6.09985 11.1855 6.3088 11.1855 6.56651ZM12.1189 7.03317H14.9188V23.8329H12.1189V7.03317Z" fill="#224798"/>
                      <path d="M18.1854 24.7663H21.9187C22.1764 24.7663 22.3853 24.5573 22.3853 24.2996V0.966659C22.3853 0.708949 22.1764 0.5 21.9187 0.5H18.1854C17.9277 0.5 17.7188 0.708949 17.7188 0.966659V24.2996C17.7188 24.5573 17.9277 24.7663 18.1854 24.7663ZM18.6521 1.43332H21.452V23.833H18.6521V1.43332Z" fill="#224798"/>
                      <path d="M5.2733 24.7662H9.00658C9.26429 24.7662 9.47323 24.5573 9.47323 24.2995V13.0997C9.47323 12.842 9.26429 12.6331 9.00658 12.6331H5.2733C5.01559 12.6331 4.80664 12.842 4.80664 13.0997V24.2995C4.80664 24.5573 5.01559 24.7662 5.2733 24.7662ZM5.73996 13.5664H8.53992V23.8329H5.73996V13.5664Z" fill="#224798"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_303_2210">
                        <rect width="28" height="28" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                    </defs>
                </svg>
            </a> -->
            <a
                href="#!"
                class="like"
                id="add_to_favorite"
                :data-product-id="props.product.id"
            >
                <!--                <img src="/design/images/icon/hearth_empty.svg" alt="" />-->
                <!--                <?php if ($product->in_favorite()) { ?>
                <img src="/design/images/icon/hearth_blue.svg" alt="" />
                <?php } else { ?>
                <img src="/design/images/icon/hearth_empty.svg" alt="" />
                <?php } ?>-->
                <img
                    v-if="favorites.includes(props.product.id)"
                    src="/design/images/icon/hearth_blue.svg"
                    alt=""
                />
                <img v-else src="/design/images/icon/hearth_empty.svg" alt="" />
            </a>
        </div>
        <span v-if="props.product.promotion === 1" class="name-spn action-spn"
            >Распродажа</span
        >
        <span v-if="props.product.new === 1" class="name-spn new-spn"
            >Новинка</span
        >
        <span v-if="props.product.hit === 1" class="name-spn xit-spn"
            >Хит продаж</span
        >
        <span v-if="props.product.archive === 1" class="name-spn archive-spn"
            >Архив</span
        >

        <div class="image">
            <a
                :href="
                    '/' +
                    props.product.category.full_url +
                    '/product-' +
                    props.product.slug
                "
            >
                <img
                    :src="'/' + props.product.image"
                    alt="Product Image"
                    class="product-img"
                />
            </a>
        </div>

        <div class="product__card-content">
            <a
                :href="
                    '/' +
                    props.product.category.full_url +
                    '/product-' +
                    props.product.slug
                "
                class="product-card-title"
            >
                {{ props.product.name }}
                <br />
                {{ props.product.brand }}
            </a>
            <div class="product__card-id">
                <span>Артикул: {{ props.product.sku }} </span>
                <!--                                <?php if ($product->count >= 1 && $product->count <= 20) { ?>-->
                <span
                    v-if="props.product.count > 0 && props.product.count <= 20"
                    class="product__card-id-orange"
                    >Заканчивается</span
                >
                <!--                <?php } elseif ($product->count >= 21) { ?>-->
                <span
                    v-else-if="props.product.count > 20"
                    class="product__card-id-green"
                    >В наличии</span
                >
                <!--                <?php } else { ?>-->
                <span v-else class="product__card-id-red">Удаленный склад</span>
                <!--                <?php } ?>-->
            </div>

            <div v-if="props.product.price && props.product.price !== 0">
                <div class="product-card-sena">
                    <p>
                        Цена:
                        {{ props.product.price.toLocaleString("ru-RU") }} руб
                    </p>
                </div>

                <div class="product__card-links">
                    <button
                        class="card-link-buy"
                        data-bs-toggle="modal"
                        data-bs-target="#modalBuy"
                        :data-price="props.product.price"
                        :data-product-id="props.product.id"
                    >
                        КУПИТЬ В 1 КЛИК
                    </button>
                    <button class="card-link-shop">
                        <img
                            src="/design/images/icon/card-shopping.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>

            <button v-else class="menager-cart">
                <p>УТОЧНИТЬ СТОИМОСТЬ</p>
            </button>
        </div>
    </div>
</template>

<style scoped></style>
